<template>
  <div style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="合辑视频详情"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form action="#" class="searchbox" :inline="true">
              <el-form-item label="">
                <el-select v-model="limits" placeholder="请选择视频权限" size="small" style="margin-right: 10px">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="state" placeholder="请选择视频状态" size="small" style="margin-right: 10px" @change="search">
                  <el-option label="全部" value="-999"></el-option>
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="审核通过" value="1"></el-option>
                  <el-option label="审核拒绝" value="-1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="Keyword" type="text" size="small" placeholder="输入关键词搜索..." @keyup.enter.native="search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="primary" size="small" @click="AddBtn" plain>+发布</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe height="calc(100% - 80px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="name" width="150px" label="视频封面">
            <template slot-scope="scope">
              <div
                class="table-img"
                @click="
                  dialogV = true
                  urlV = scope.row.url
                "
              >
                <el-image style="width: 150px; height: 150px" :src="scope.row.cover ? scope.row.cover : scope.row.aliyunCoverUrl" fit="contain" />
                <p v-if="scope.row.durationResult">{{ scope.row.durationResult }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="视频信息">
            <template slot-scope="scope">
              <div class="table-info">
                <h4>{{ scope.row.name }}</h4>
                <div class="info-item">
                  视频分类：<span v-if="scope.row.videoCategory.parent">{{ scope.row.videoCategory.parent.name }}/</span>{{ scope.row.videoCategory.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="isCharge" label="是否收费" align="center">
            <template slot-scope="scope">
              <div class="isCharge" v-if="scope.row.isCharge">
                <p>{{ scope.row.vipPrice || 0 }}元(会员)</p>
                <p>{{ scope.row.userPrice || 0 }}元(非会员)</p>
              </div>
              <div class="isCharge" v-else>免费</div>
            </template>
          </el-table-column>
          <el-table-column prop="limits" label="权限" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.limits == 0">完全公开</span>
              <span v-if="scope.row.limits == 1">内部员工开放</span>
              <span v-if="scope.row.limits == 2">私密发布</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalSale" label="总销量" align="center"></el-table-column>
          <el-table-column prop="limits" label="视频数据" align="center">
            <template slot-scope="scope">
              <div class="info-item">
                <i class="el-icon-chat-line-round" style="color: #5c83f2; font-size: 16px; margin-right: 2px"></i>评论数：
                {{ scope.row.commentCount }}
              </div>
              <div class="info-item"><i class="el-icon-star-on" style="color: #e6a23c; font-size: 18px; margin-right: 2px"></i>收藏数：{{ scope.row.collectCount }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="200" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.state == '0'" effect="plain">待审核</el-tag>
              <el-tag type="success" v-if="scope.row.state == '1'" effect="plain">审核通过</el-tag>
              <el-tag type="danger" v-if="scope.row.state == '-1'" effect="plain">审核拒绝</el-tag>
              <p v-if="scope.row.state == '-1'">拒绝原因：{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column width="280px" label="发布时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)" v-if="scope.row.state == 1">编辑</span>
              <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
              <span class="primary optionBtn" @click="TradeBtn(scope.row)" v-if="scope.row.state == 1">交易详情</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogV" :before-close="closeDialog">
      <div class="flex-center">
        <video v-if="urlV" :src="urlV" class="avatar video-avatar" controls controlslist="nodownload noremoteplayback" oncontextmenu="return false"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Videos } from '../../../components/HospitalDomain/Videos/Videos'

export default {
  name: 'OwnAlone',
  components: {
    bread,
  },
  data() {
    var videos = new Videos(this.TokenClient, this.Services.Healthy)
    return {
      dialogV: false,
      urlV: '',
      VideosDomain: videos,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      Keyword: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 0,
      DataTotal: 1,
      tableData: [],
      limits: '',
      state:'-999',
      collectionId: this.$route.query.collectionId,
      statusOption: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '完全公开',
        },
        {
          value: '1',
          label: '内部用户开放',
        },
        {
          value: '2',
          label: '私密发布',
        },
      ],
    }
  },
  mounted() {
    // this.LoadCategory();
    this.getList()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    closeDialog() {
      this.dialogV = false
      this.urlV = ''
    },
    LoadCategory(success, error) {
      var _this = this
      _this.VideosDomain.Categories(
        function (data) {
          _this.videosCategory = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      // 查询合辑下视频
      _this.VideosDomain.GetCollectionVideo(
        _this.collectionId,
        _this.limits,
        _this.Keyword,
        _this.PageIndex,
        _this.state,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    SearchInputKeydown() {
      this.PageIndex = 1
      this.getList()
    },
    search() {
      this.PageIndex = 1
      this.getList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    AddBtn() {
      this.$router.push({
        path: '/Videos/AddVideos',
        query: { title: '新增合辑视频', collectionId: this.collectionId },
      })
    },
    EditBtn(item) {
      this.$router.push({
        path: '/Videos/AddVideos',
        query: {
          id: item.id,
          title: '编辑视频',
          collectionId: this.collectionId,
        },
      })
    },
    TradeBtn(item) {
      this.$router.push({
        path: '/Videos/TradeList',
        query: {
          id: item.id,
          totalSale: item.totalSale,
          videoCategoryName: item.videoCategory.name,
        },
      })
    },
    RemoveBtn(item) {
      var _this = this
      this.$confirm('确定删除此条视频吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          _this.VideosDomain.Remove(
            item.id,
            item.aliyunVideoId,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getList()
            },
            function (error) {
              console.log(error)
            }
          )
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
/*content*/

.table-top {
  margin-bottom: -10px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  width: auto;
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

.table-img {
  position: relative;
  width: 150px;
  height: 120px;
  overflow: hidden;
}

.table-img img {
  width: 100%;
  height: auto;
}

.table-img p {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
.isCharge {
  color: #67c23a;
}

.isCharge p {
  color: #f56c6c;
}
.table-info h4 {
  margin-bottom: 25px;
}

.table-info .el-row {
  margin-bottom: 15px;
}
</style>
